import './JobDetails.css'
import moment from 'moment'
import Modal from "react-modal";
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react'
import { DynamicLink, Url } from '../../EnvoirnmentSetup/APIs'
import { useSelectedCardContext } from '../../Context/Context'
import { handleCopyDetails } from '../../constants/constantFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { deleteAPICall, getAPICall, postAPICall, putAPICall } from '../../APIMethods/APIMethods'

import completed from "../../assets/Success.json";
import logOutLogo from '../../assets/logOutLogo.svg'
import statsIcon from '../../assets/jobStatsIcon.svg'
import shareIcon from '../../assets/shareJobIcon.svg'
import deleteIcon from '../../assets/deleteJobIcon.svg'
import calenderIcon from '../../assets/calenderIcon.svg'
import similarJobIcon from '../../assets/similarJobs.svg'
import recruiterIcon from '../../assets/recruiterIcon.svg'
import jobLocationIcon from '../../assets/jobDetailsLocation.svg'
import recruiterListIcon from '../../assets/recruiterListIcon.svg'
import companyPostedIcon from '../../assets/companyPostedIcon.svg'

const JobDetails = () => {
    const params = useParams()
    const navigate = useNavigate()
    const locationData = useLocation()
    const { IsLoading, setIsLoading, setWarning, setAlert } = useSelectedCardContext()

    const [userID, setUserID] = useState()
    const [jobDetails, setJobDetails] = useState({})
    const [deletePopup, setDeletePopUp] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false);
    const [ApplicationStatus, setApplicationStatus] = useState(null)

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minWidth: "40%",
            borderRadius: "25px",
            padding: "10px",
        },
        overlay: {
            zIndex: 3
        },
    };

    useEffect(() => {
        GetJobDetails()
    }, [locationData?.state])

    const GetJobDetails = (actionStatus) => {
        if (actionStatus == "Applied") {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
        let jobId = locationData?.state?.jobId ?? params?.jobId
        if (!jobId) {
            navigate(-1)
        }
        const user = JSON.parse(sessionStorage.getItem('user'))
        setUserID(user?.userId)
        // console.log('.....', jobId ?? 0);
        if (locationData?.state?.Flow == 'Reports_View') {
            getAPICall(Url.GetPostApplicationStatus.replace('{jobId}', jobId))
                .then((res) => {
                    setIsLoading(false)
                    setJobDetails(res?.DATA);
                })
        } else {
            getAPICall(Url.getJobDetailsByJobIDApplicant.replace('{jobID}', jobId))
                .then((res) => {
                    setIsLoading(false)
                    setApplicationStatus(res?.DATA)
                    setJobDetails({ ...res?.DATA?.job });
                })
        }
        // viewed job
        let PositionStatus = {
            viewed: true,
            user: {
                userId: user?.userId,
            },
            job: {
                jobId: jobId,
            },
        };
        setIsLoading(true)
        postAPICall(Url.postApplyJob, PositionStatus)
            .then((res) => {
                setIsLoading(false)
            })
    }
    // console.log('>>>>>>>>>>>', userID)
    const handleApplyJob = (jobId) => {
        setIsLoading(true)
        let PositionStatus = {
            viewed: true,
            closed: false,
            intrested: true,
            user: {
                userId: userID,
            },
            job: {
                jobId: jobId,
            },
        };
        postAPICall(Url.postApplyJob, PositionStatus)
            .then((res) => {
                setIsLoading(false)
                setSuccessPopup(true)
                GetJobDetails('Applied')
                // console.log('>>>>>>>>>>>', res)
            })

        if (locationData?.state?.notificationId) {
            putAPICall(Url?.GetNotifications, {
                notificationId: locationData?.state?.notificationId,
                seen: true,
                delete: true,
            })
        }
    }
    const handleDeleteJob = () => {
        setDeletePopUp(true)
    }
    const handleConfirmDelete = () => {
        // console.log('del...............................', ApplicationStatus)
        deleteAPICall(Url.DeleteRejectedApplications.replace('{userJobId}', ApplicationStatus?.userJobId)).then(res => {
            console.log(res);
            if (res?.SUCCESS) {
                setDeletePopUp(false)
                setAlert('Job has been deleted successfully.')
                navigate(-1)
            } else {
                setWarning(res?.message);
                // console.log(res.message);
            }
        });
    }
    const formatJobCount = (count) => {
        if (count === null || count === undefined) return '00';
        return count < 10 ? `0${count}` : `${count}`;
    };

    return (
        <div className='jobDetailsMainDiv'>
            <div className="jobBreadCrumb">
                <ComponentAndScreenConstant.Breadcrumbs currentPage={locationData?.state?.Flow == 'Reports_View' ? ["Job Stats", "Job Details", `${locationData?.state?.screen ?? ''} Job`] : ["Job Details", `${locationData?.state?.screen ?? ''} Job`]} />
            </div>
            <div className='jobDetailsAll'>
                <div className="jobInfoDetails">
                    <div className='jobInformation'>
                        <span className='jobTitleDetail'>{jobDetails?.designation?.length > 0 ? jobDetails?.designation[0]?.designationName : ""}</span>
                        <span className='companyNameDetails'>{jobDetails?.companyName}</span>
                        <span className='salaryDetails'>Salary: ₹ {jobDetails?.isSalaryVisible && jobDetails?.salary ? jobDetails?.salary + " LPA" : "Not Disclosed"}</span>
                        <div className='mainJobWorkDetails'>
                            <div className='jobWorkDetails'>
                                <span className='jobTypeDetails'>{jobDetails?.jobType?.jobType}</span>
                                <span className='workTypeDetails'>{jobDetails?.workMode?.workMode}</span>
                                <span className='expDetail'>{jobDetails?.experience?.experience}</span>
                                <span className='positions'>Openings: {jobDetails?.noOfOpenings}</span>
                            </div>
                            <div className='jobActionButton'>
                                <button type="button"
                                    onClick={() => {
                                        let jobIdData = jobDetails?.jobId;
                                        // let jobIdData = jobDetails?.jobId
                                        let message = `${DynamicLink}Main/JobDetails/${jobIdData}`
                                        if (handleCopyDetails(message)) {
                                            setAlert('Job URL copied successfully')
                                        }
                                    }}
                                >
                                    {/* <img src={shareIcon} alt="" /> */}
                                    Copy Job URL
                                </button>
                                {ApplicationStatus?.closed &&
                                    <button type="button" onClick={() => { handleDeleteJob() }}>
                                        <img src={deleteIcon} alt="delete job icon" />
                                    </button>}
                            </div>
                        </div>
                    </div>
                    <div className="locationAndSimilar">
                        <button type='button' className='similarJob' onClick={() => { navigate('/Main/SimilarJobs', { state: { jobId: jobDetails?.jobId, headerHide: true, screen: locationData?.state?.screen, } }) }}>
                            <img src={similarJobIcon} alt="Similar Jobs" /> <small>Similar Jobs</small>
                        </button>
                        <span className='jobDetailLocation'>
                            <img src={jobLocationIcon} alt="" /> <small> {jobDetails?.state?.map(state => state.stateName).join(", ")}</small>
                        </span>
                        {/* jobDetails?.state?.map(state => state.stateName).join(", ") */}
                    </div>
                </div>
                <div className='activeTill'>
                    <span className='activeDate'>Last Date to Apply : <img src={calenderIcon} alt="" /> {jobDetails?.formattedActiveTillDate}</span>
                    <span className='uploadedDiv'>{moment(jobDetails?.postedDateTime).fromNow()}</span>
                </div>
                {locationData?.state?.Flow != 'Reports_View' ? <>
                    <div className='jobDescription'>
                        {ApplicationStatus?.intrested && <>
                            <h4 style={{ fontWeight: '600', padding: '0 0 0px 0', margin: 0 }}>Application Status</h4>
                            <ComponentAndScreenConstant.ApplicationStatusBar ApplicationStatus={ApplicationStatus} />
                        </>}
                        <div className='keyWordInfoAndApplyBtn'>
                            <div className='keyWordInfo'>
                                <span className='keyWordsSelect'>Keywords</span>
                                <p>{jobDetails?.skill?.map(skill => skill.name).join(", ")}</p>
                            </div>
                            <div className='applyBtn'>
                                {/* {(!ApplicationStatus?.intrested) ? <button type="button"><img src={QuikLogo} alt="" /> Apply</button> : ""} */}
                                {(!ApplicationStatus?.intrested) ? <button onClick={(e) => { handleApplyJob(jobDetails?.jobId) }} type="button">{/* <img src={QuikLogo} alt="" /> */} Apply</button> : ""}
                                {/* {sessionStorage.getItem('jobApplied') != "done" && <button onClick={(e)=>{handleApplyJob(jobDetails?.jobId)}} type="button"><img src={QuikLogo} alt=""/> Apply</button> } */}
                            </div>
                        </div>
                        <div className='jobDescriptionDetailsMain'>
                            <div className='jobDescriptionDetails'>
                                <span className='jobDescriptionDetailsHead'>Job Description</span>
                                <p>{jobDetails?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className='moreDetails'>
                        <span className='moreDetailsHead'>More Details</span>
                        <div className='moreDetailsAll'>
                            <div className='jobStatsDetails'
                                onClick={() => {
                                    navigate('/Main/jobStatus', {
                                        state: {
                                            job: jobDetails,
                                            headerHide: true,
                                            Flow: 'Reports_View',
                                            jobId: jobDetails?.jobId,
                                            screen: locationData?.state?.screen,
                                        }
                                    })
                                }}>
                                <div className='moreIcon'>
                                    <img src={statsIcon} alt="" />
                                </div>
                                <h1>Job Stats</h1>
                            </div>
                            <div className='jobStatsDetails'
                                onClick={() => {
                                    navigate('/Main/recruiterInfo', {
                                        state: {
                                            job: jobDetails,
                                            headerHide: true,
                                            screen: locationData?.state?.screen,
                                        }
                                    })
                                }}>
                                <div className='moreIcon'>
                                    <img src={recruiterIcon} alt="" />
                                </div>
                                <h1>Recruiter Info</h1>
                            </div>
                            <div className='jobStatsDetails'
                                onClick={() => {
                                    navigate('/Main/recruiterListedJobs', {
                                        state: {
                                            jobId: jobDetails?.jobId,
                                            Flow: 'Recruiter_Jobs',
                                            recruiterDetails: jobDetails?.jobPoster,
                                            recruiterName: jobDetails?.jobPoster?.fullName,
                                            screen: locationData?.state?.screen,
                                            headerHide: true
                                        }
                                    })
                                }}>
                                <div className='moreIcon'>
                                    <img src={recruiterListIcon} alt="" />
                                </div>
                                <h1>Recruiter's Listing</h1>
                            </div>
                            <div className='jobStatsDetails'
                                onClick={() => {
                                    navigate('/Main/companyPostedJob', {
                                        state: {
                                            Flow: 'Company_Jobs',
                                            companyUid: jobDetails?.companyUid,
                                            companyName: jobDetails?.companyName,
                                            screen: locationData?.state?.screen,
                                            headerHide: true,
                                        }
                                    })
                                }}>
                                <div className='moreIcon'>
                                    <img src={companyPostedIcon} alt="" />
                                </div>
                                <h1>All Jobs Posted <span>{jobDetails?.companyName}</span></h1>
                            </div>
                        </div>
                    </div>
                </> :
                    <>
                        <div className='jobStats'>
                            <div className='jobStatsDiv'>
                                <div>
                                    {jobDetails?.applicantCount?.intrested ? jobDetails?.applicantCount?.intrested < 10 ? "0" + jobDetails?.applicantCount?.intrested : jobDetails?.applicantCount?.intrested : '00'}
                                </div>
                                <p>Applied</p>
                            </div>
                            <div className='jobStatsDiv'>
                                <div>
                                    {jobDetails?.applicantCount?.viewd ? jobDetails?.applicantCount?.viewd < 10 ? "0" + jobDetails?.applicantCount?.viewd : jobDetails?.applicantCount?.viewd : '00'}
                                </div>
                                <p>Searched/ Viewed</p>
                            </div>
                            <div className='jobStatsDiv'>
                                <div>
                                    {jobDetails?.applicantCount?.sendto ? jobDetails?.applicantCount?.sendto < 10 ? "0" + jobDetails?.applicantCount?.sendto : jobDetails?.applicantCount?.sendto : '00'}
                                </div>
                                <p>Candidate Notified</p>
                            </div>
                        </div>
                    </>}
            </div>
            <ComponentAndScreenConstant.CommonModal
                isOpen={successPopup}
            >
                <div className='appliedModal' >
                    <span
                        onClick={() => setSuccessPopup(false)}
                        className="appliedModalCross"
                        role='appliedModalCross'
                    >
                        ×
                    </span>
                    <Lottie
                        loop={true}
                        animationData={completed}
                        className="delAccModalErrLottie"
                    />
                    <span className='congratulationsText'> Congratulations! </span>
                    <div className="appliedPara">{"Your Application is Successfully Submitted!"}</div>
                    <div className='congratulationsButtons'>
                        <button
                            onClick={() => navigate(-1)}
                            style={{ color: '#01774A', backgroundColor: 'transparent' }}
                        >
                            Go Back
                        </button>
                        <button
                            onClick={() => navigate('/Main/SimilarJobs', { state: { jobId: jobDetails?.jobId, headerHide: true, screen: locationData?.state?.screen, } })}
                        >
                            Explore Similar Jobs
                        </button>
                    </div>
                </div>
            </ComponentAndScreenConstant.CommonModal>

            <Modal
                isOpen={deletePopup}
                onRequestClose={() => setDeletePopUp(false)}
                ariaHideApp={false}
                style={customStyles}
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className="delAccModal1">
                    <div className="delAccModalCrossDiv" style={{ backgroundColor: "#FFF4F4" }}>
                        <p role='deleteClose' onClick={() => setDeletePopUp(false)} className="delAccModalCrossPara" style={{ color: "#BC0000" }}>×</p>
                    </div>
                </div>
                <div className='logoutLogoDiv' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={logOutLogo} />
                </div>
                <div className="delAccModalErrMsg" style={{ fontWeight: "normal", marginBottom: 0 }}>
                    Are you sure you want to delete Job?
                </div>
                <div className="okBtnModal" style={{ display: "flex", gap: "2%", margin: '0 0 2% 0' }}>
                    <button onClick={() => setDeletePopUp(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #BC0000", color: "#BC0000", cursor: "pointer" }}>Cancel</button>
                    <button style={{ backgroundColor: "#BC0000", cursor: "pointer" }} onClick={handleConfirmDelete}>Delete</button>
                </div>
            </Modal>
        </div>
    )
}

export default JobDetails